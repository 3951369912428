
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getFollowageList, exportFollowageList } from "@/api/request/behavior";

//组件
@Component({
  name: "Follow",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //请求参数
  private listQuery: any = {
    //页码数据
    row: 20,
    page: 1,

    //请求数据
    type: 0,
    nickname: "",
    userid: undefined,
  };

  //关注类型
  private typeoptions: any[] = [
    { value: 0, label: "全部" },
    { value: 1, label: "关注查询" },
    { value: 2, label: "被关注查询" },
  ];

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);

    //获取数据
    const { data } = await getFollowageList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理重置
  private handleReset(): void {
    //数据赋值
    this.listQuery.page = 1;
    this.listQuery.type = 0;
    this.listQuery.nickname = "";
    this.listQuery.userid = undefined;

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //处理导出
  private async exportHandler() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);

    //数据赋值
    const { data } = await exportFollowageList(this.listQuery);

    //保存数据
    saveAs(data, "关注查询列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }
}
